html {
    font-size: 14px;
}

// Small devices
@include media-breakpoint-up(sm) {
    html {
        font-size: 14px;
    }
}

// Medium devices
@include media-breakpoint-up(md) {
    html {
        font-size: 14px;
    }
}

// Large devices
@include media-breakpoint-up(lg) {
    html {
        font-size: 14px;
    }
}

// Extra-Large devices
@include media-breakpoint-up(xl) {
    html {
        font-size: 14px;
    }
}

// Extra-Extra-Large devices
@include media-breakpoint-up(xxl) {
    html {
        font-size: 16px;
    }
}

.font-3xl {
    font-size: 3rem !important;
}

.font-2xl {
    font-size: 2.5rem !important;
}

.font-xxl {
    font-size: 1.8rem !important;
}

.font-xl {
    font-size: 1.4rem;
}

.font-lg {
    font-size: 1.2rem !important;
}

.font-md {
    font-size: 1rem !important;
}

.font-sm {
    font-size: 0.9rem !important;
}

.font-xs {
    font-size: 0.8rem !important;
}

.font-xxs {
    font-size: 0.6rem !important;
}

.weight-light {
    font-weight: 300 !important;
}

.weight-normal {
    font-weight: 400 !important;
}

.weight-medium {
    font-weight: 600 !important;
}

.weight-heavy {
    font-weight: 700 !important;
}

// Small tag with font class
.font-xxl > small {
    font-size: 1rem;
}

.font-xl > small {
    font-size: 0.9rem;
}

.font-lg > small {
    font-size: 0.8rem;
}

.font-md > small {
    font-size: 0.7rem;
}
