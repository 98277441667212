// Generate max-width classes
.w-5 {
    width: 5% !important;
}
.w-10 {
    width: 10% !important;
}
.w-15 {
    width: 15% !important;
}
.w-20 {
    width: 20% !important;
}
.w-30 {
    width: 30% !important;
}
.w-35 {
    width: 35% !important;
}
.w-40 {
    width: 40% !important;
}
.w-45 {
    width: 45% !important;
}
.w-55 {
    width: 55% !important;
}
.w-60 {
    width: 60% !important;
}
.w-65 {
    width: 65% !important;
}
.w-70 {
    width: 70% !important;
}
.w-80 {
    width: 80% !important;
}
.w-85 {
    width: 95% !important;
}
.w-90 {
    width: 90% !important;
}
.w-95 {
    width: 95% !important;
}

// Generate max-width classes
.mw-5 {
    max-width: 5% !important;
}
.mw-10 {
    max-width: 10% !important;
}
.mw-15 {
    max-width: 15% !important;
}
.mw-20 {
    max-width: 20% !important;
}
.mw-30 {
    max-width: 30% !important;
}
.mw-35 {
    max-width: 35% !important;
}
.mw-40 {
    max-width: 40% !important;
}
.mw-50 {
    max-width: 50% !important;
}
.mw-45 {
    max-width: 45% !important;
}
.mw-55 {
    max-width: 55% !important;
}
.mw-60 {
    max-width: 60% !important;
}
.mw-65 {
    max-width: 65% !important;
}
.mw-70 {
    max-width: 70% !important;
}
.mw-80 {
    max-width: 80% !important;
}
.mw-85 {
    max-width: 95% !important;
}
.mw-90 {
    max-width: 90% !important;
}
.mw-95 {
    max-width: 95% !important;
}
