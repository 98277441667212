:root {
    --color-bg1: #6ec3f4;
    --color-bg2: #a178e3;
    --color1: 113, 193, 240;
    --color2: 112, 79, 209;
    --color3: 113, 193, 240;
    --color4: 112, 79, 209;
    --circle-size: 80%;
    --blending: hard-light;
}

@keyframes moveInCircle {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes moveVertical {
    0% {
        transform: translateY(-50%);
    }
    50% {
        transform: translateY(50%);
    }
    100% {
        transform: translateY(-50%);
    }
}

@keyframes moveHorizontal {
    0% {
        transform: translateX(-50%) translateY(-10%);
    }
    50% {
        transform: translateX(50%) translateY(10%);
    }
    100% {
        transform: translateX(-50%) translateY(-10%);
    }
}

.telxl-loading-background {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: linear-gradient(
        to bottom right,
        var(--color-bg1),
        var(--color-bg2)
    );
    top: 0;
    left: 0;

    svg {
        position: fixed;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
    }

    .gradients-container {
        filter: url(#goo) blur(100px);
        width: 100%;
        height: 100%;
    }

    .g1 {
        position: absolute;
        background: radial-gradient(
                circle at center,
                rgba(var(--color1), 0.8) 0,
                rgba(var(--color1), 0) 50%
            )
            no-repeat;
        mix-blend-mode: var(--blending);

        width: var(--circle-size);
        height: var(--circle-size);
        top: calc(50% - var(--circle-size) / 2);
        left: calc(50% - var(--circle-size) / 2);

        transform-origin: center center;
        animation: moveVertical 45s ease infinite;

        opacity: 1;
    }

    .g2 {
        position: absolute;
        background: radial-gradient(
                circle at center,
                rgba(var(--color2), 0.8) 0,
                rgba(var(--color2), 0) 50%
            )
            no-repeat;
        mix-blend-mode: var(--blending);

        width: var(--circle-size);
        height: var(--circle-size);
        top: calc(50% - var(--circle-size) / 2);
        left: calc(50% - var(--circle-size) / 2);

        transform-origin: calc(50% - 400px);
        animation: moveInCircle 30s reverse infinite;

        opacity: 1;
    }

    .g3 {
        position: absolute;
        background: radial-gradient(
                circle at center,
                rgba(var(--color3), 0.8) 0,
                rgba(var(--color3), 0) 50%
            )
            no-repeat;
        mix-blend-mode: var(--blending);

        width: var(--circle-size);
        height: var(--circle-size);
        top: calc(50% - var(--circle-size) / 2 + 200px);
        left: calc(50% - var(--circle-size) / 2 - 500px);

        transform-origin: calc(50% + 400px);
        animation: moveInCircle 60s linear infinite;

        opacity: 1;
    }

    .g4 {
        position: absolute;
        background: radial-gradient(
                circle at center,
                rgba(var(--color4), 0.8) 0,
                rgba(var(--color4), 0) 50%
            )
            no-repeat;
        mix-blend-mode: var(--blending);

        width: var(--circle-size);
        height: var(--circle-size);
        top: calc(50% - var(--circle-size) / 2);
        left: calc(50% - var(--circle-size) / 2);

        transform-origin: calc(50% - 200px);
        animation: moveHorizontal 60s ease infinite;

        opacity: 0.7;
    }
}
