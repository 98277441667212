@import './variables.scss';
@import './bootstrap.scss';
@import './fonts.scss';
@import './scroll.scss';
@import './background.scss';
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.bubble.css';

.task-row {
    height: 80px;
}

.primary-hover {
    cursor: pointer;

    &:hover {
        color: $blue-primary;
    }

    &.disabled {
        color: $grey-secondary;

        &:hover {
            color: $grey-secondary;
            cursor: default;
        }
    }
}

.user-initials {
    font-size: 1.75rem;
    width: 60px;
    height: 60px;
    background-color: $grey-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-initials-sm {
    font-size: 1.25rem;
    width: 45px;
    height: 45px;
    background-color: $grey-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-wrap .modal-dialog {
    min-width: 600px;
}

.telxl-loading-text {
    color: $white;
    font-size: 1.5rem;
    font-weight: lighter;
}

.telxl-loading-text-title {
    color: $white;
    font-size: 3rem;
    font-weight: lighter;
}
