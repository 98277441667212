@import './variables.scss';
@import './sizing.scss';

// Bootstrap variables override
$body-bg: $white;
$body-color: $font-default;
$font-family-sans-serif: 'source-sans-pro', sans-serif;
$primary: $blue-primary;
$secondary: $grey-primary;
$success: $green-primary;
$warning: $yellow-primary;
$danger: $red-primary;
$info: $purple-primary;

$toast-header-color: $black;
$toast-header-border-color: $white;
$modal-inner-padding: 2rem;
$modal-header-padding-x: 2rem;
$modal-header-padding-y: 1rem;
$modal-content-border-width: 0;
$modal-content-color: $black;
$badge-border-radius: 20px;

$dropdown-dark-bg: $grey-secondary;
$dropdown-dark-color: $black;
$dropdown-dark-link-hover-bg: $grey-primary;
$dropdown-dark-border-color: $grey-secondary;

$dropdown-border-radius: 4px;
$dropdown-border-color: $grey-tertiary;
$dropdown-link-hover-bg: $grey-secondary;
$dropdown-link-active-bg: $grey-secondary;
$dropdown-link-active-color: $grey-primary;

$dropdown-spacer: 0;

$breadcrumb-font-size: 0.9rem;
$breadcrumb-active-color: $grey-primary;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1400px,
    xxl: 1800px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1340px,
    xxl: 1720px,
);

@import './bootstrap/scss/bootstrap';

@media (min-width: 0px) and (max-width: 575px) {
    .d-xs-block {
        display: block !important;
    }
}

@media (min-width: 0px) and (max-width: 2560px) {
    .container-telxl {
        max-width: 2560px !important;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-right: 0;
        margin-left: 0;
    }
}

@media (min-width: 2561px) {
    .container-telxl {
        max-width: 2560px !important;
        width: 100%;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        margin-right: auto;
        margin-left: auto;
    }
}

body {
    background-color: $background !important;
}

// Additional text styles.
.text-socials {
    color: $purple-primary !important;
}

.text-email {
    color: $blue-email !important;
}

.text-webchat {
    color: $blue-webchat !important;
}

// Additional border styles.
.border-socials {
    border-color: $purple-primary !important;
}

.border-email {
    border-color: $blue-email !important;
}

.border-webchat {
    border-color: $blue-webchat !important;
}

// Text boxes with background.
.text-bg-primary {
    background-color: $blue-primary !important;
    color: $blue-secondary !important;
}

.text-bg-secondary {
    background-color: $grey-secondary !important;
    color: $grey-primary !important;
}

.text-bg-success {
    background-color: $green-primary !important;
    color: $green-secondary !important;
}

.text-bg-warning {
    background-color: $yellow-primary !important;
    color: $yellow-secondary !important;
}

.text-bg-info {
    background-color: $purple-primary !important;
    color: $purple-secondary !important;
}

.text-bg-danger {
    background-color: $red-primary !important;
    color: $red-secondary !important;
}

// Fix for switch inputs
input[role='switch'] {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3.5' fill='rgba(255,255,255,1.0)'/></svg>") !important;
}

// Move to dropdown code eventually.
.btn-dropdown-transparent {
    @include button-variant(
        transparent,
        $grey-primary,
        $grey-primary,
        transparent,
        $black-secondary,
        $black-secondary
    );
}

.card-body-sm {
    padding: calc(var(--bs-card-spacer-y) / 2) var(--bs-card-spacer-x);
}

.btn-xs {
    @extend .btn;
    @extend .btn-sm;

    padding: 0.15rem 0.4rem;
    font-size: 0.775rem;
    line-height: 1.2;
    border-radius: 0.15rem;
}
