@import './variables.scss';

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background: $scroll-background;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background: $panel-font;
    border-radius: 5px;
    transition: background 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
    background: $black-secondary;
    transition: background 0.5s ease-in-out;
}
